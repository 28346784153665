import { Injectable } from '@angular/core';

import { SessionRateDto, SessionRatesUpdateDto } from '../dtos/session/session-rate.dto';
import { SessionRate, SessionRatesUpdate } from '../models/session-rate';

import { MapperFromDto } from './mappers';

/** Session rate mapper. */
@Injectable({ providedIn: 'root' })
export class SessionRateMapper implements MapperFromDto<SessionRateDto, SessionRate> {

	/** @inheritdoc */
	public fromDto(dto: SessionRateDto): SessionRate {
		return {
			allowOffered: dto.allow_offered,
			durationInMin: dto.duration,
			fee: dto.fee,
			id: dto.id,
			rate: dto.rate ?? 0,
			sessionType: dto.session_type,
			template: dto.template,
			note: dto.note,
		};
	}

	/**
	 * Map data to consultation rates update data dto.
	 * @param model Consultation rates.
	 */
	public toUpdateDto(model: SessionRatesUpdate): SessionRatesUpdateDto {
		return {
			rates: model.rates.map(consultationRate => ({
				id: consultationRate.id,
				allow_offered: consultationRate.allowOffered,
				rate: consultationRate.rate === 0 ? null : Number(consultationRate.rate),
				note: consultationRate.note,
			})),
		};
	}
}
