import { enumToArray } from '../utils/enum-to-array';

/** Content Type. */
export enum ContentType {
	JPEG = 'image/jpeg',
	PNG = 'image/png',
	PDF = 'application/pdf',
	WEBM = 'video/webm',
	MP4 = 'video/mp4',
}

export namespace ContentType {

	const imageContentTypes = [
		ContentType.JPEG,
		ContentType.PNG,
	];

	/** Get array of all available content types. */
	export function toArray(): ContentType[] {
		return enumToArray(ContentType);
	}

	/** Get list of content types which represent images. */
	export function getImageTypes(): ContentType[] {
		return [...imageContentTypes];
	}

	/**
	 * Whether content type represent an image.
	 * @param contentType Content type to check.
	 */
	export function isImage(contentType: ContentType): boolean {
		return imageContentTypes.includes(contentType);
	}
}
